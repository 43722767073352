<mat-form-field *ngIf="form.enabled" [ngStyle]="{ 'width.px': width }">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-select [formControl]="form.get(idField)" #singleSelect (selectionChange)="selectionChanged()" [disabled]="readonly" panelClass="testClass">
    <mat-option>
      <ngx-mat-select-search
        [noEntriesFoundLabel]="'Common.RecordsNotFound' | translate"
        [placeholderLabel]="searchLabel"
        [formControl]="searchForm"
      ></ngx-mat-select-search>
    </mat-option>

    <mat-option
      *ngFor="let option of data"
      [value]="option.id"
      matTooltip="{{ option.label }}"
      [matTooltipShowDelay]="1000"
    >
      {{ option.label }}
    </mat-option>
<!--
    <mat-option
      *ngFor="let option of data"
      [value]="option.id"
      (onSelectionChange)="selectionChanged(option.id, option.label)"
    >
      {{ option.label }}
    </mat-option> -->
    <mat-option *ngIf="showSelectedItem(data)" [value]="selectedItem[idField]">
      {{ selectedItem[labelField] }}
    </mat-option>
  </mat-select>

  <!-- <div matSuffix class="display-flex">
    <mat-spinner *ngIf="loading" [diameter]="24"> </mat-spinner>
    <button mat-button type="button" [disabled]="form.disabled || readonly" (click)="clear($event)">
      <mat-icon>close</mat-icon>
    </button>
    <button *ngIf="!hideButton" mat-button type="button" [disabled]="form.disabled" (click)="onButtonClick($event)">
      <mat-icon>add</mat-icon>
    </button>
  </div> -->

  <!-- <div matSuffix class="display-flex">
    <mat-spinner *ngIf="loading" [diameter]="24"> </mat-spinner>
     <button mat-button type="button" [disabled]="form.disabled" (click)="onButtonClick($event)"><mat-icon>add</mat-icon></button>
  </div> -->

  <mat-error *ngIf="form.get(idField).hasError('required')">
    {{ 'ValidationErrors.FieldRequired' | translate }}
  </mat-error>
</mat-form-field>

<mat-form-field *ngIf="!form.enabled">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input matInput type="text" [formControl]="form.get(labelField)" readonly />
  <a *ngIf="itemLink && form.disabled" mat-button matSuffix [routerLink]="itemLink">
    <mat-icon class="color-primary">visibility </mat-icon>
  </a>
</mat-form-field>
