import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-authorised-layout',
  templateUrl: './authorised-layout.component.html',
  styleUrls: ['./authorised-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthorisedLayoutComponent implements OnInit, OnDestroy {
  mobileQuery: MediaQueryList;
  public year = new Date().getFullYear();
  public opened: boolean;

  isAuthenticated = false;

  constructor(
    private router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public oidcSecurityService: OidcSecurityService
  ) {
    this.opened = true;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  ngOnInit(): void {
   
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  public showNav() {
    return this.router.url !== '/signin';
  }

  private _mobileQueryListener: () => void;
}
