import { DeviceForm } from './device-form/device-form';
import { ReportForm } from './report-form/report-form';
import { UserForm } from './user-form/user.form';

export class FormFactory {
  constructor() {}

  //Dictionaries
  public getReportForm() {
    return new ReportForm();
  }
  public getDeviceForm() {
    return new DeviceForm();
  }
  public getUserForm() {
    return new UserForm();
  }
}
