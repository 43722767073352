import { NgModule, ChangeDetectorRef, ApplicationRef, Provider } from '@angular/core';
import { CommonModule, AsyncPipe } from '@angular/common';
import { ServiceGroupNameSortByPipe } from './service-group-name-sort-by.pipe';
import { ContractorSortByPipe } from './contractor-sort-by.pipe';
import { ErrorResponsePipe } from './error-response.pipe';
import { EnumTranslatePipe } from './enum-translate.pipe';
import { TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { FilterPipe } from './filter.pipe';

// export const pipes = [ServiceGroupNameSortByPipe, ContractorSortByPipe, ErrorResponsePipe, EnumTranslatePipe];

@NgModule({
    imports: [CommonModule, TranslateModule],
    declarations: [ServiceGroupNameSortByPipe, ContractorSortByPipe, ErrorResponsePipe, EnumTranslatePipe, FilterPipe],
    exports: [
        ServiceGroupNameSortByPipe,
        ContractorSortByPipe,
        ErrorResponsePipe,
        EnumTranslatePipe,
        TranslateModule,
        FilterPipe,
    ],
    providers: [
        ServiceGroupNameSortByPipe,
        ContractorSortByPipe,
        ErrorResponsePipe,
        EnumTranslatePipe,
        TranslateService,
        FilterPipe,
    ]
})
export class PipesModule {}
