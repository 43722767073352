import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';
import { WsTypeEnum } from '@shared/models/enums/wstype-enum';
import { TypeEnum } from '@shared/models/enums/type-enum';

export class DeviceForm extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(undefined),
      deviceName: new UntypedFormControl(null, Validators.required),
      deviceSerialNumber: new UntypedFormControl(null, Validators.required),
      deviceLocalization: new UntypedFormControl(null, Validators.required),
      userRole: new UntypedFormControl(null),
    });
  }
  forAdd() {
    return this;
  }
}
