import { UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss'],
})
export class BaseDialogComponent implements OnInit {
  @Input() title: string;
  _loading: boolean;
  @Input() canSave: boolean=true;

  @Input() set loading(val: boolean) {
    this._loading = val;
  }
  get loading(): boolean {
    return this._loading;
  }

  @Input() form: UntypedFormGroup;
  @Input() showSaveButton = true;

  @Output() cancel = new EventEmitter();

  @Output() confirm = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  public onCancelAction() {
    this.cancel.emit();
  }

  public onConfirmAction() {
    this.confirm.emit();
  }
}
