import { DatePipe, registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import localePl from '@angular/common/locales/pl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RootStoreModule } from 'src/root-store/root-store.module';
import { SharedModule } from 'src/shared';
import { ErrorInterceptor } from 'src/shared/http-interceptors/error.interceptor';
import { JwtInterceptor } from 'src/shared/http-interceptors/jwt.interceptor';
import { MatPaginatorIntlPlService } from 'src/shared/services/mat-paginator-intl-pl.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthorisedLayoutComponent } from './layout/authorised-layout/authorised-layout.component';
import { AutoLoginComponent } from './layout/auto-login/auto-login.component';
import { HeaderComponent } from './layout/header/header.component';
import { MainMenuComponent } from './layout/main-menu/main-menu.component';
import { UnauthorisedComponent } from './layout/unauthorised/unauthorised.component';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthModule } from 'angular-auth-oidc-client';
import { FooterComponent } from './footer/footer.component';

registerLocaleData(localePl, 'pl');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http as any, './assets/i18n/', '.json');
}
export function getBaseUrl() {
  return environment.apiRoot;
}

// export function loadConfig(oidcConfigService: OidcConfigService) {
//   return () => oidcConfigService.withConfig(environment.identityConfig);
// }

@NgModule({
  declarations: [
    AppComponent,
    AuthorisedLayoutComponent,
    UnauthorisedComponent,
    AutoLoginComponent,
    HeaderComponent,
    MainMenuComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    EffectsModule.forRoot([]),
    RootStoreModule,
    AuthModule.forRoot({
      config: {
        authority: environment.identityConfig.stsServer,
        redirectUrl: window.location.origin + '/#/authorised/dashboard',
        postLogoutRedirectUri:
          window.location.origin + '/#/authorised/dashboard',
        clientId: environment.identityConfig.clientId,
        scope: environment.identityConfig.scope,
        responseType: 'code',
        silentRenew: true,
        silentRenewUrl: window.location.origin + '/silent-renew.html',
        renewTimeBeforeTokenExpiresInSeconds: 15,
        useRefreshToken: true,
        secureRoutes: [
          environment.apiRoot,
          environment.identityConfig.stsServer,
        ],
      },
    }),
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pl' },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlPlService },
    DatePipe,

    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(`cancel`, `../assets/cancel.svg`);
  }
}
