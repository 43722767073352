import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HasClaimDirective } from './has-claim.directive';
// import { ApiUsersApiService } from '../api-services/api-services';

@NgModule({
    declarations: [
        HasClaimDirective
    ],
    imports: [],
    exports: [
        HasClaimDirective
    ],
    providers: [
    // ApiUsersApiService
    ]
})
export class DirectivesModule {}
