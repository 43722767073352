<mat-toolbar color="primary" class="toolbar">
  <div class="header-left">
    <button mat-icon-button (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <a [routerLink]="['']">
      <h1 class="mb-0 app-title" style="color: white">
        {{ "App.title" | translate }}
      </h1>
    </a>
  </div>
  <div class="flex-spacer"></div>
  <div style="display: contents" class="header-right mr-4">
    <button
      #notificationsButton
      class="buttonc"
      style="color: white; margin-right: 1%"
      (click)="toggleNotifications()"
      *ngIf="!isAdmin"
    >
      <div
        *ngIf="
          notificationsNumber != 0;
          then showNotificationsIconWithBadge;
          else showNotificationIconWithoutBadge
        "
      ></div>
    </button>

    <span *ngIf="isDebug" style="color: white" class="mr-2 align-middle">{{
      roles
    }}</span>
    <span id="userName" style="color: white" class="mr-2 align-middle"
      >{{ user }} &nbsp;</span
    >
    <div *ngIf="isAuthenticated; else notLoggedButton">
      <button
        id="logout"
        style="margin-right: 20px"
        mat-raised-button
        (click)="logout()"
      >
        Wyloguj
      </button>
    </div>
    <ng-template id="notLogged" #notLoggedButton>
      <button mat-raised-button (click)="login()">Zaloguj</button>
    </ng-template>
  </div>
  <ng-content></ng-content>
</mat-toolbar>

<!-- Notification List -->
<div
  *ngIf="showNotifications && !isAdmin"
  class="notifications-list"
  [ngStyle]="getNotificationsListStyle()"
>
  <mat-list >
    <mat-list-item *ngFor="let notification of userNotifications">
      <div class="notification" style="min-width: 100%">
        <label style="width: 70%">
          {{ notification.message }}
        </label>

        <button
          class="action-button relative-bottom"
          style="float: right"
          mat-raised-button
          color="warn"
          (click)="removeNotification(notification.id)"
        >
          <mat-icon>delete</mat-icon>{{ "Common.Delete" | translate }}
        </button>
      </div>
    </mat-list-item>
  </mat-list>
</div>

<ng-template #showNotificationsIconWithBadge>
  <mat-icon
    class="buttonc"
    [matBadge]="notificationsNumber"
    matBadgeColor="warn"
  >
    notifications
  </mat-icon>
</ng-template>

<ng-template #showNotificationIconWithoutBadge>
  <mat-icon class="buttonc"> notifications</mat-icon>
</ng-template>
