import { FormGroup, UntypedFormControl, AbstractControl } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() form: UntypedFormControl;
  @Input() inputType: string;
  @Input() step: string;
  @Input() autocomplete: string = undefined;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() width: number;
  @Input() name: string;
  @Input() required: boolean = false;
  @Input() readonly: boolean = false;
  @Input() value: any;
  hasFormControl() {
    return !!this.form;
  }
  constructor() {}

  ngOnInit() {}
}
