import { FilterType } from '@shared/models/enums/filter-type.enum';
import { IPageableQuery } from '../../interfaces/i-pageable-query';

export class PageableQuery implements IPageableQuery {
  constructor() {
    this.desc = false;
    this.orderBy = 'id';
    this.pageNumber = 0;
    this.pageSize = 25;
  }

  desc: boolean;
  orderBy: string;
  pageNumber: number;
  pageSize: number;
  searchTerm?: string;
}
