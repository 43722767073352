import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { StoreState } from './store-state';
import { reducer as tableSettings } from './tables/tables.reducer';
import { storageMetaReducer } from './storage-meta-reducer';
import { reducer as userData } from './user-data/user-data.reducer';
import { reducer as pageableReports } from './factures/reports.reducer';
import { reducer as modules } from './module/module.reducer';
import { reducer as notifications } from './notifications/notification.reducer';
import { reducer as generics } from './generics/generics.reducer';

export const reducers: ActionReducerMap<StoreState> = {
  tableSettings,
  userData,
  pageableReports,
  modules,
  notifications,
  generics,
};

export const MetaReducers: MetaReducer<StoreState>[] = [storageMetaReducer];
