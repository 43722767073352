import { Action, createReducer, on } from '@ngrx/store';
import {
  loadPageableGenerics,
  loadPageableGenericsSuccess,
  loadPageableGenericsFailure,
  setPageableGenericsFilters,
  setPageableGenericsFiltersSuccess,
} from './generics.actions';
import { GetPageableDictionaryQuery } from 'src/shared/queries/get-pageable-dictionary.query';
import { DictionaryDto } from '@shared/models/contracts/dictionary.dto';
import { PageableDto } from '@shared/models/pageable.dto';

export const genericsFeatureKey = 'generics';

export interface State {
  [x: string]: any;
  pageableGenerics?: DictionaryDto<string, PageableDto<any>>;
  genericsFilters?: DictionaryDto<string, GetPageableDictionaryQuery>;
}

export const initialState: State = {
  pageableGenerics: new DictionaryDto<string, PageableDto<any>>(),
  genericsFilters: new DictionaryDto<string, GetPageableDictionaryQuery>(),
};

const genericsReducer = createReducer(
  initialState,

  on(setPageableGenericsFilters, (state, { data, key }) =>
    setPageableGenericsFiltersFunc(state, null, key)
  ),
  on(setPageableGenericsFiltersSuccess, (state, { data, key }) =>
    setPageableGenericsFiltersFunc(state, data, key)
  ),

  on(loadPageableGenerics, (state, { key }) =>
    setPageableGenerics(state, null, key)
  ),
  on(loadPageableGenericsSuccess, (state, { data, key }) =>
    setPageableGenerics(state, data, key)
  ),
  on(loadPageableGenericsFailure, (state, action) => state)
);

function setPageableGenerics(
  state: State,
  data: PageableDto<any> | null,
  key: string
) {
  const pageableGenerics = Array.isArray(state.pageableGenerics)
    ? state.pageableGenerics
    : new DictionaryDto<string, PageableDto<any> | any>();
  const generics = pageableGenerics.find((_) => _.key === key);
  if (generics != null) {
    generics.value = data;
  } else {
    pageableGenerics.push({ key, value: data });
  }
  return {
    ...state,
    pageableGenerics,
  };
}

function setPageableGenericsFiltersFunc(
  state: State,
  data: GetPageableDictionaryQuery | null,
  key: string
) {
  const genericsFilters = Array.isArray(state.genericsFilters)
    ? state.genericsFilters
    : new DictionaryDto<string, GetPageableDictionaryQuery>();
  const filter = genericsFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    genericsFilters.push({ key, value: data });
  }
  return {
    ...state,
    genericsFilters,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return genericsReducer(state, action);
}
