<div class="app-container" [class.is-mobile]="mobileQuery.matches">


  <app-header> </app-header>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav mode="side" class="example-sidenav" opened="true">
      <app-main-menu></app-main-menu>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="shadow-box"></div>
      <div>
        <router-outlet></router-outlet>
      </div>
      <ng-template #notLoggedContent>
        <span>Nie jesteś zalogowany</span>
      </ng-template>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>