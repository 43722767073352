import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller/base-api-caller';

@Injectable({
  providedIn: 'root',
})
export class NotificationsApiCallerService extends BaseApiCaller {
  protected controllerPath = 'notification';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public deleteNotification(id: string) {
    return this.delete(id);
  }


  getNotifications(userRole: string) {
    const params = this.prepareParams({userRole});

    return this.get<any>('', { params });
  }
}
