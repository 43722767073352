import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, from } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private oidcSecurityService: OidcSecurityService;

  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.oidcSecurityService) {
      this.oidcSecurityService = this.injector.get(OidcSecurityService);
    }

    if (this.oidcSecurityService) {
      return from(this.oidcSecurityService.getAccessToken()).pipe(
        switchMap((token: string) => {
          let requestToForward = req;

          if (token) {
            const tokenValue = `Bearer ${token}`;
            requestToForward = req.clone({ setHeaders: { Authorization: tokenValue } });
          } else {
            console.log('No token available: NO auth header!');
          }

          return next.handle(requestToForward);
        })
      );
    } else {
      console.log('OidcSecurityService undefined: NO auth header!');
      return next.handle(req);
    }
  }
}
