import { createSelector } from '@ngrx/store';
import { StoreState } from '../store-state';
import { State } from './generics.reducer';

const selectGenericsFeature = (state: StoreState) => {
  return state.generics;
};

export const selectPageableGenerics = createSelector(
  selectGenericsFeature,
  (state: State | any, props: { key: string }) => {
    const result = state.pageableGenerics
      ? state.pageableGenerics.find(
          (_) => _.key === props.key && _.value != null
        )
      : null;
    return result ? result.value : null;
  }
);

export const selectAllPageableGenericsFilters = createSelector(
  selectGenericsFeature,
  (state: State | any) => state.genericsFilters
);
export const selectPageableGenericsFilters = createSelector(
  selectGenericsFeature,
  (state: State | any, props: { key: string }) => {
    const result = state.genericsFilters
      ? state.genericsFilters.find(
          (_) => _.key === props.key && _.value != null
        )
      : null;
    return result ? result.value : null;
  }
);
