import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SearchTermMapDto } from '@shared/models/config/search-term-map.dto';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  map,
  withLatestFrom
} from 'rxjs/operators';
import { OrderByMapDto } from 'src/shared/models/config/order-by-map.dto';
import { PageSizeMapDto } from 'src/shared/models/config/page-size-map.dto';
import { SortTypeMapDto } from 'src/shared/models/config/sort-type-map.dto';
import { StoreState } from '../store-state';
import {
  getOrderBy,
  getOrderByFailure,
  getOrderBySuccess,
  getPageSize,
  getPageSizeFailure,
  getPageSizeSuccess,
  getSearchTerm,
  getSearchTermFailure,
  getSearchTermSuccess,
  getSortType,
  getSortTypeFailure,
  getSortTypeSuccess,
  setOrderBy,
  setOrderByFailure,
  setOrderBySuccess,
  setPageSize,
  setPageSizeFailure,
  setPageSizeSuccess,
  setSearchTerm,
  setSearchTermFailure,
  setSearchTermSuccess,
  setSortType,
  setSortTypeFailure,
  setSortTypeSuccess,
} from './tables.actions';
import {
  selectOrderBy,
  selectPageSize,
  selectSearchTerm,
  selectSortType
} from './tables.selectors';

@Injectable()
export class TablesEffects {
  setPageSize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageSize),
      withLatestFrom(this.store$.select(selectPageSize)),
      concatMap((data) => {
        return of(data).pipe(
          map((data) =>
            setPageSizeSuccess({ data: this.getPageSizeMapFromData(data) })
          ),
          catchError((error) => of(setPageSizeFailure({ error })))
        );
      })
    )
  );

  getPageSize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPageSize),
      concatMap(({ tableKey }) => {
        return of(tableKey).pipe(
          map((_) => getPageSizeSuccess({ pageSize: Number.parseInt(_) })),
          catchError((error) => of(getPageSizeFailure({ error })))
        );
      })
    )
  );

  setOrderBy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setOrderBy),
      withLatestFrom(this.store$.select(selectOrderBy)),
      concatMap((data) => {
        return of(data).pipe(
          map((data) =>
            setOrderBySuccess({ data: this.getOrderByMapFromData(data) })
          ),
          catchError((error) => of(setOrderByFailure({ error })))
        );
      })
    )
  );

  getOrderBy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOrderBy),
      concatMap(({ tableKey }) => {
        return of(tableKey).pipe(
          map((_) => getOrderBySuccess({ orderBy: _ })),
          catchError((error) => of(getOrderByFailure({ error })))
        );
      })
    )
  );

  setSortType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSortType),
      withLatestFrom(this.store$.select(selectSortType)),
      concatMap((data) => {
        return of(data).pipe(
          map((data) =>
            setSortTypeSuccess({ data: this.getSortTypeFromData(data) })
          ),
          catchError((error) => of(setSortTypeFailure({ error })))
        );
      })
    )
  );

  getSortType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSortType),
      concatMap(({ tableKey }) => {
        return of(tableKey).pipe(
          map((_) => getSortTypeSuccess({ sortType: _ })),
          catchError((error) => of(getSortTypeFailure({ error })))
        );
      })
    )
  );

  setSearchTerm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSearchTerm),
      withLatestFrom(this.store$.select(selectSearchTerm)),
      concatMap((data) => {
        return of(data).pipe(
          map((data) =>
            setSearchTermSuccess({ data: this.getSearchTermMapFromData(data) })
          ),
          catchError((error) => of(setSearchTermFailure({ error })))
        );
      })
    )
  );

  getSearchTerm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSearchTerm),
      concatMap(({ tableKey }) => {
        return of(tableKey).pipe(
          map((_) => getSearchTermSuccess({ searchTerm: _ })),
          catchError((error) => of(getSearchTermFailure({ error })))
        );
      })
    )
  );

  getPageSizeMapFromData(data: any) {
    const state = Array.isArray(data[1]) ? (data[1] as PageSizeMapDto[]) : [];
    const pageSize = data[0].data;
    const existingValue = state.find((_) => _.key == pageSize.key);
    if (existingValue) {
      existingValue.pageSize = pageSize.pageSize;
    } else {
      const entity: PageSizeMapDto = new PageSizeMapDto(
        pageSize.key,
        pageSize.pageSize
      );
      state.push(entity);
    }
    return state.map((_) => Object.assign({}, _));
  }

  getOrderByMapFromData(data: any) {
    const state = Array.isArray(data[1]) ? (data[1] as OrderByMapDto[]) : [];
    const sort = data[0].data;
    const existingValue = state.find((_) => _.key == sort.key);
    if (existingValue) {
      existingValue.orderBy = sort.orderBy;
    } else {
      const entity: OrderByMapDto = new OrderByMapDto(sort.key, sort.orderBy);
      state.push(entity);
    }
    return state.map((_) => Object.assign({}, _));
  }

  getSortTypeFromData(data: any) {
    const state = Array.isArray(data[1]) ? (data[1] as SortTypeMapDto[]) : [];
    const sortType = data[0].data;
    const existingValue = state.find((_) => _.key == sortType.key);
    if (existingValue) {
      existingValue.desc = sortType.desc;
    } else {
      const entity: SortTypeMapDto = new SortTypeMapDto(
        sortType.key,
        sortType.orderBy
      );
      state.push(entity);
    }
    return state.map((_) => Object.assign({}, _));
  }

  getSearchTermMapFromData(data: any) {
    const state = Array.isArray(data[1]) ? (data[1] as SearchTermMapDto[]) : [];
    const searchTerm = data[0].data;
    const existingValue = state.find((_) => _.key == searchTerm.key);
    if (existingValue) {
      existingValue.searchTerm = searchTerm.searchTerm;
    } else {
      const entity: SearchTermMapDto = new SearchTermMapDto(
        searchTerm.key,
        searchTerm.SearchTerm
      );
      state.push(entity);
    }
    return state.map((_) => Object.assign({}, _));
  }

  constructor(private actions$: Actions, private store$: Store<StoreState>) {}
}
