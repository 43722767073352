import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import { merge, pick } from 'lodash-es';
import { StoreState } from './store-state';

function setSavedState(state: any, localStorageKey: string) {
  const json = JSON.stringify(state);
  localStorage.setItem(localStorageKey, json);
}

function getSavedState(localStorageKey: string): any {
  return JSON.parse(localStorage.getItem(localStorageKey) as string);
}

export const storeKey = '__app_storage__';
const stateKeys = ['tableSettings', 'userData'];

export const storageMetaReducer: MetaReducer<StoreState, Action> = (
  reducer: ActionReducer<StoreState, Action>
) => {
  let onInit = true;
  return function (state: StoreState | any, action: Action): StoreState {
    const nextState = reducer(state, action);
    if (onInit) {
      onInit = false;
      const savedState = getSavedState(storeKey);
      return merge(nextState, savedState);
    }
    const stateToSave = pick(nextState, stateKeys);
    setSavedState(stateToSave, storeKey);
    return nextState;
  };
};
