import { createAction, props } from '@ngrx/store';
import { PageableDto } from '@shared/models/pageable.dto';
import { DictionaryType } from 'src/shared/models/enums/DictionaryType';
import { GetPageableDictionaryQuery } from 'src/shared/queries/get-pageable-dictionary.query';

export const loadPageableGenerics = createAction(
  '[FormsData] Load Pageable Generics',
  props<{ key: DictionaryType }>()
);
export const loadPageableGenericsSuccess = createAction(
  '[FormsData] Load Pageable Generics Success',
  props<{ data: PageableDto<any>; key: DictionaryType }>()
);
export const loadPageableGenericsFailure = createAction(
  '[FormsData] Load Pageable Generics Failure',
  props<{ error: any }>()
);

export const setPageableGenericsFilters = createAction(
  '[Authors] Set Pageable Genericss Filters',
  props<{ data: GetPageableDictionaryQuery; key: DictionaryType }>()
);
export const setPageableGenericsFiltersSuccess = createAction(
  '[Authors] Set Pageable Genericss Filters Success',
  props<{ data: GetPageableDictionaryQuery; key: DictionaryType }>()
);
export const setPageableGenericsFiltersFailure = createAction(
  '[Authors] Set Pageable Genericss Filters Failure',
  props<{ error: any }>()
);

