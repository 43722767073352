import { ErrorStateMatcher } from '@angular/material/core';
import { PageableQuery } from '../../../models/contracts/pageable-query';
import { UntypedFormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';

export class PaginationStateMatcher implements ErrorStateMatcher {
  constructor(private filters: PageableQuery) {}
  isErrorState(control: UntypedFormControl, form: FormGroupDirective | NgForm): boolean {
    return this.filters.pageSize > control.value;
  }
}

export class PaginatorControl extends UntypedFormControl {
  get totalPages() {
    if (this.filters == null) {
      return 0;
    }
    return Math.ceil(this.total / this.filters.pageSize);
  }
  constructor(private total: number, private filters: PageableQuery) {
    super(1);

    this.setValidators([this.isLessThanTotal, Validators.min(1)]);
  }

  isLessThanTotal = (control: PaginatorControl) => {
    if (control.value == null || this.filters == null || control.value > this.totalPages) {
      return { isLessThanTotal: true };
    }
    return null;
  };
}
