import { createAction, props } from '@ngrx/store';
import { GetPageableQuery } from '@shared/models/queries/get-pageable.query';
import { PageableDto } from '@shared/models/contracts/pageable.dto';
import { ReportDto } from '@shared/models/report-dto';
import { ReportHistoryDto } from '@shared/models/report-history.dto';
import { AttachmentProgressDto } from '@shared/models/attachment-progress.dto';

export const loadPageableReports = createAction(
  '[Reports] Load Pageable Reports',
  props<{ filters? : any }>()
);

export const loadPageableReportsSuccess = createAction(
  '[Reports] Load Pageable Reports Success',
  props<{ data: PageableDto<ReportDto> }>()
);

export const loadPageableReportsFailure = createAction(
  '[Reports] Load Pageable Reports Failure',
  props<{ error: any }>()
);

export const addReport = createAction('[Report] Add Report', props<{ data: ReportDto,attachments: File[] }>());

export const addReportSuccess = createAction('[Report] Add Report Success', props<{ id: string }>());

export const addReportFailure = createAction('[Report] Add Report Failure', props<{ error: any }>());

export const updateReport = createAction('[Report] Update Report', props<{ data: ReportDto }>());

export const updateReportSuccess = createAction('[Report] Update Report Success', props<{ id: string }>());

export const updateReportFailure = createAction('[Report] Update Report Failure', props<{ error: any }>());

export const deleteReport = createAction('[Report] Delete Report', props<{ id: string }>());

export const deleteReportSuccess = createAction('[Report] Delete Report Success', props<{ id: string }>());

export const deleteReportFailure = createAction('[Report] Delete Report Failure', props<{ error: any }>());

export const sendAttachment = createAction('[Attachment] Add Attachment', props<{ data: FormData }>());

export const sendAttachmentSuccess = createAction('[Attachment] Add Attachment Success', props<{data: any}>());

export const sendAttachmentFailure = createAction('[Attachment] Add Attachment Failure', props<{ error: any }>());

export const loadUnhandledReportsForClient = createAction(
  '[UnhandledReportsForClient] Load UnhandledReportsForClient',
  props<{ clientName: string }>()
);

export const loadPdf = createAction('[Pdf] Load Pdf', props<{ ReportId: string }>());

export const loadPdfSuccess = createAction('[Pdf] Load Pdf Success', props<{ data: Blob }>());

export const loadPdfFailure = createAction('[Pdf] Load Pdf Failure', props<{ error: any }>());


export const loadReportHistory = createAction('[Operation] Load Report History', props<{ reportId: string }>());

export const loadReportHistorySuccess = createAction(
  '[Operation] Load Report History Success',
  props<{ data: ReportHistoryDto }>()
);

export const loadReportHistoryFailure = createAction('[Operation] Load Report History Failure', props<{ error: any }>());

export const addReportAtachments = createAction(
  '[Operation] Add Report Atachments',
  props<{ data: File[]; reportId: string }>()
);
export const addReportAtachmentsScheduled = createAction('[Operation] Add Report Atachments Scheduled');

export const addReportAtachmentsSuccess = createAction('[Operation] Add Report Atachments Success');

export const addReportAtachmentProgress = createAction(
  '[Operation] Add Report Atachments Progress',
  props<{ info: AttachmentProgressDto; reportId: string }>()
);

export const addReportAtachmentProgressSuccess = createAction(
  '[Operation] Add Report Atachments Progress Success',
  props<{ data: AttachmentProgressDto[]; reportId: string }>()
);

export const addReportAtachmentsFailure = createAction(
  '[Operation] Add Report Atachments Failure',
  props<{ error: any }>()
);

export const loadReport = createAction('[Report] Load Report', props<{ id: string }>());

export const loadReportSuccess = createAction('[Report] Load Report Success', props<{ data: ReportDto }>());

export const loadReportFailure = createAction('[Report] Load Report Failure', props<{ error: any }>());
