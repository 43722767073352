import { Component, HostListener, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { StoreState } from '@store/store-state';
import { loadAllClaims, storeUserData } from '@store/user-data/user-data.actions';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isAuthenticated = false;

  constructor(private oidcSecurityService: OidcSecurityService, translate: TranslateService, private router: Router, private store$: Store<StoreState>,) {
    translate.use(environment.lang);
    translate.get('App.title').subscribe((_) => (document.title = _));
  }

  ngOnInit() {
    this.oidcSecurityService
      .checkAuth()
      .subscribe(({ isAuthenticated, userData }) => {
        this.isAuthenticated = isAuthenticated;

        if (userData != null) {
          this.store$.dispatch(storeUserData({ data: userData }));
          this.store$.dispatch(loadAllClaims());
        }

        if (!isAuthenticated) {
          if ('#/autologin' !== window.location.hash) {
            this.write();
            this.router.navigate(['/autologin']);
          }
        }

        if (isAuthenticated) {
          this.navigateToStoredEndpoint();
        }
      });
  }
  @HostListener('window:beforeunload') beforeUnload() {
    if (this.isAuthenticated) {
      this.write();
    }
  }

  private navigateToStoredEndpoint() {
    let path = this.read('redirect') as string;
    if (path == null || path.length < 2) {
      return;
    }
    path = path.slice(1);
    if (path.toString().includes('/unauthorized')) {
      this.router.navigate(['/']);
    } else {
      this.router.navigateByUrl(path);
    }
  }

  private read(key: string): any {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }

    return;
  }

  private write(): void {
    localStorage.setItem('redirect', JSON.stringify(window.location.hash));
  }
}
