import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { MetaReducers, reducers } from './reducers-index';
import { TablesEffects } from './tables/tables.effects';
import { GenericsEffects } from './generics/generics.effects';
import { UserDataEffects } from './user-data/user-data.effects';
import { EffectsModule } from '@ngrx/effects';
import { ApiCallerFactory } from '@shared/api-services/base-api-caller/api-caller-factory';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, {
      metaReducers: MetaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forFeature([TablesEffects, UserDataEffects, GenericsEffects]),
  ],
  providers: [ApiCallerFactory],
})
export class RootStoreModule {}
