import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IPageableQuery } from '@shared/interfaces/i-pageable-query';
import { ResponseDto } from '@shared/models/contracts/response-dto';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface HttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}
export abstract class BaseApiCaller {
  protected controllerPath: string | any;

  constructor(protected httpClient: HttpClient) {}
  protected get<T>(path: string, options?: HttpOptions): Observable<T | any> {
    return this.httpClient
      .get<ResponseDto<T>>(this.getFullPath(path), options)
      .pipe(
        tap((_) => this.checkError(_)),
        map((_) => _.result)
      );
  }
  protected getWithoutMapping<T>(path: string, options?: HttpOptions): Observable<T | any> {
    return this.httpClient
      .get<ResponseDto<T>>(this.getFullPath(path), options)
      .pipe(
        tap((_) => this.checkError(_)),
        map((_) => _)
      );
  }

  protected getFullResponse<T>(
    path: string,
    body: any,
    options?: HttpOptions
  ): Observable<T | any> {
    return this.httpClient
      .post<ResponseDto<T>>(this.getFullPath(path), body, options)
      .pipe(
        tap((_) => this.checkError(_)),
        map((_) => _)
      );
  }

  protected getById<T>(
    path: string,
    options?: HttpOptions
  ): Observable<T | any> {
    return this.httpClient
      .get<ResponseDto<T>>(this.getFullPath(path), options)
      .pipe(
        tap((_) => this.checkError(_)),
        map((_) => _)
      );
  }

  protected postWithReponse<T>(
    path: string,
    body: any,
    options?: HttpOptions
  ): Observable<T | any> {
    return this.httpClient
      .post<ResponseDto<T>>(this.getFullPath(path), body, options)
      .pipe(
        tap((_) => this.checkError(_)),
        map((_) => _.result)
      );
  }

  protected postWithReponseSimpleType<T>(
    path: string,
    body: any,
    options?: HttpOptions
  ): Observable<T> {
    return this.httpClient
      .post<T>(this.getFullPath(path), body, options)
      .pipe();
  }

  protected post(
    path: string,
    body: any,
    options?: HttpOptions
  ): Observable<unknown> {
    return this.httpClient.post(this.getFullPath(path), body, options);
  }

  protected put(
    path: string,
    body: any,
    options?: HttpOptions
  ): Observable<unknown> {
    return this.httpClient.put(this.getFullPath(path), body, options);
  }

  protected delete(path: string, options?: HttpOptions): Observable<unknown> {
    return this.httpClient.delete(this.getFullPath(path), options);
  }

  protected deleteWithBody(path: string, ids: any): Observable<unknown> {
    const options = { body: ids };
    return this.httpClient.delete(this.getFullPath(path), options);
  }

  protected getPageableParams(filter: IPageableQuery) {
    const result = {
      desc: filter.desc.toString(),
      orderBy: filter.orderBy,
      pageNumber: filter.pageNumber.toString(),
      pageSize: filter.pageSize.toString(),
      searchTerm: filter?.searchTerm?.toString(),
    };
    if (filter.searchTerm != null && filter.searchTerm.length > 0) {
      result.searchTerm = filter.searchTerm;
    }

    return result;
  }

  protected prepareParams(data: any) {
    let result = new HttpParams();

    Object.keys(data).forEach((item) => {
      if (data[item] != null) {
        result = result.set(item, data[item]);
      }
    });

    return result;
  }

  protected getFullPath(path: string) {
    return environment.apiRoot + '/api/' + this.controllerPath + '/' + path;
  }
  protected getPath(path: string) {
    return environment.apiRoot + '/api/' + this.controllerPath;
  }

  protected getIdentityFullPath(path: string) {
    return environment.identityRoot + '/' + path;
  }

  private checkError(response: ResponseDto<any>) {
    if (response.isError) {
      throw new Error('Error with request');
    }
  }
}
