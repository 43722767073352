import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { fileDuplicateFound } from '../../services/snack-bar-messaged';
import { SnackBarWrapperService } from '../../services/snack-bar-wrapper.service';

@Component({
  selector: 'app-files-upload',
  templateUrl: './files-upload.component.html',
  styleUrls: ['./files-upload.component.scss'],
})
export class FilesUploadComponent implements OnInit {
  @Input() form: UntypedFormControl;
  dragOverStatus: 'none' | 'drag-over' | 'drop' = 'none';

  constructor(private _matSnack: SnackBarWrapperService) {}

  files: File[] = [];

  ngOnInit() {}

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.addFiles(event.dataTransfer.files);

    this.dragOverStatus = 'drop';
  }

  addFiles(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);

      if (
        this.files.find((_) => _.lastModified === file.lastModified && _.name === file.name && _.size === file.size) ==
        null
      ) {
        this.files.push(file);
      } else {
        this._matSnack.openMessage(fileDuplicateFound, 'error');
      }
    }

    if (this.form != null) {
      this.form.setValue(this.files);
    }
    this.form.markAsDirty();
  }

  removeFile(index: number) {
    this.files = this.files.filter((v, i) => i !== index);
    this.form.setValue(this.files);

  }

  onDragOver(event) {
    this.dragOverStatus = 'drag-over';
    event.stopPropagation();
    event.preventDefault();
  }

  onDragLeave(event) {
    this.dragOverStatus = 'none';
  }
}
