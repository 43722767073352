import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-spinner-button',
  templateUrl: './spinner-button.component.html',
  styleUrls: ['./spinner-button.component.scss'],
})
export class SpinnerButtonComponent implements OnInit {
  @Output() clicked = new EventEmitter<any>(null);
  @Input() set disabled(value: boolean) {

  }

  _isLoading = false;
  constructor() {}

  ngOnInit() {}

  @Input() set label(val: string) {
  }
  @Input() set isLoading(val: boolean) {
    this._isLoading = val;
  }

  public btOnClick() {
    this.clicked.next();
  }
}
