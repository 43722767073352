import {
  Overlay,
  OverlayRef,
  PositionStrategy,
  ScrollStrategy,
  ScrollStrategyOptions,
} from '@angular/cdk/overlay';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { NotificationsApiCallerService } from '@shared/api-services/notification-api-caller.service';
import { AppPermission } from '@shared/models/enums/app-permission.enum';
import { OverlayWrapperService } from '@shared/overlay/overlay-wrapper.service';
import { NotificationService } from '@shared/services/notification.service';
import { SecurityService } from '@shared/services/security.service';
import { StoreState } from '@store/store-state';
import {
  loadAllClaims,
  storeUserData,
} from '@store/user-data/user-data.actions';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() snav: MatSidenav;
  isAuthenticated: boolean;
  userData: any;
  user: string;
  isDebug = false;
  roles: string;
  notificationsNumber: number;
  overlayRef: OverlayRef;
  overlayPosition: PositionStrategy;
  scrollStrategy: ScrollStrategy;
  open: boolean;
  isAdmin: boolean;
  showNotifications = false;
  userNotifications: any;
  @ViewChild('notificationsButton', { read: ElementRef })
  notificationsButton: ElementRef;



  constructor(
    private overlayWrapperService: OverlayWrapperService,
    private store$: Store<StoreState>,
    private overlay: Overlay,
    private notificationApiCaller: NotificationsApiCallerService,
    private readonly sso: ScrollStrategyOptions,
    private notificationService: NotificationService,
    public oidcSecurityService: OidcSecurityService,
    private securityService: SecurityService
  ) {
    this.isDebug = environment.production !== true;
    this.scrollStrategy = this.sso.block();
    this.open = false;
  }

  ngOnInit() {
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated }) => {
      this.isAuthenticated = isAuthenticated;
    });
    this.oidcSecurityService.userData$.subscribe((userData) => {
      if (userData != null) {
        console.log("userdata", userData);
        this.userData = userData;
        this.user = userData.userData.email;
        this.store$.dispatch(storeUserData({ data: this.userData }));
        this.store$.dispatch(loadAllClaims());
        if(this.userData.userData.EnumPermission == 32767){
          this.isAdmin = true;
        }
        else{
          this.isAdmin = false;
        }

        if (!this.isAdmin) {
          this.getUserNotifications(userData?.userData?.role);

          this.notificationService.startConnection().then(() => {
            this.notificationService.joinGroup(userData?.userData?.role);
            this.receiveNewReportNotification();
            this.removeReportNotification();
          });
        }
      }
    });
  }
  private receiveNewReportNotification(): void {
    this.notificationService.onReceiveReportNotification(
      (notification: any) => {
        if (notification == null) return;

        this.userNotifications.unshift(notification);
        this.notificationsNumber = +1;
      }
    );
  }

  private removeReportNotification(): void {
    this.notificationService.onRemoveReportNotification((notification: any) => {
      this.userNotifications = this.userNotifications.filter(
        (x) => x.id != notification.id
      );
      if (this.notificationsNumber != 0) {
        this.notificationsNumber = this.notificationsNumber - 1;
      }
    });
  }

  private getUserNotifications(userRole: string): void {
    this.notificationApiCaller
      .getNotifications(userRole)
      .subscribe((res: any) => {
        this.userNotifications = res;
        this.notificationsNumber = res.length;
      });
  }

  getNotificationsListStyle() {
    if (this.notificationsButton) {
      const rect =
        this.notificationsButton.nativeElement.getBoundingClientRect();
      return {
        top: `${rect.bottom}px`,
        left: `${rect.left - 330}px`,
      };
    }
    return {};
  }

  toggleNotifications() {
    this.showNotifications = !this.showNotifications;
  }

  removeNotification(id: any) {
    this.notificationApiCaller.deleteNotification(id).subscribe((res: any) => {
      this.userNotifications = this.userNotifications.filter((x) => x.id != id);
      //jezeli response 200 to filter
    });
  }

  login() {
    this.oidcSecurityService.authorize();
  }

  logout() {
    this.oidcSecurityService.logoff().subscribe((x) => {
    });
  }
}
