import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DictionaryType } from '@shared/models/enums/DictionaryType';
import { MyBaseApiCaller } from './my-base-api-caller.service';

@Injectable()
export class ApiCallerFactory {
  constructor(private httpClient: HttpClient) {}

  public getApiImplementation<T>(
    dictionaryType: DictionaryType
  ): MyBaseApiCaller<T> {
    const apiCallerService = new MyBaseApiCaller<T>(this.httpClient);
    apiCallerService.setControllerPath(dictionaryType.toString());
    return apiCallerService;
  }
  public getIdentityImplementation<T>(
    dictionaryType: DictionaryType
  ): MyBaseApiCaller<T> {
    const apiCallerService = new MyBaseApiCaller<T>(this.httpClient);
    apiCallerService.setIdentityControllerPath(dictionaryType.toString());
    return apiCallerService;
  }
}
