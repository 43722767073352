
import { FormControl, FormGroup, Validators } from '@angular/forms';


export class UserForm extends FormGroup {
  constructor() {
    super({
      dataKey: new FormControl(undefined),
      email: new FormControl(undefined, Validators.required),
      firstName: new FormControl(undefined, Validators.required),
      lastName: new FormControl(undefined, Validators.required),
      password: new FormControl(undefined),// tylko add
      passwordPin: new FormControl(undefined),
      permissions: new FormControl([]),
      roles: new FormControl([], Validators.required),
      userId: new FormControl(undefined),
      userName: new FormControl(undefined),// tylko add
    });
  }

  forAdd() {
    return this;
  }
}
