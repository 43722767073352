import { Component } from '@angular/core';
import { MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';

@Component({
  template: `<span>{{ 'Errors.AfterCreatingOrderWhileSendingFile' | translate }}</span>
    <button class="mr4" mat-icon-button (click)="snackBarRef.dismiss()">
      <mat-icon>close</mat-icon>
    </button>`,
})
export class ErrorSendingAttachmentsComponent {
  constructor(public snackBarRef: MatSnackBarRef<ErrorSendingAttachmentsComponent>) {}
}
