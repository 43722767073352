import { Injectable } from '@angular/core';
import { _MAT_INK_BAR_POSITIONER } from '@angular/material/tabs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  finalize,
  map,
  withLatestFrom,
} from 'rxjs/operators';
import { StoreState } from '../store-state';
import {
  loadPageableGenerics,
  loadPageableGenericsFailure,
  loadPageableGenericsSuccess,
  setPageableGenericsFilters,
  setPageableGenericsFiltersFailure,
  setPageableGenericsFiltersSuccess,
} from './generics.actions';
import { GetPageableQuery } from 'src/shared/queries/get-pageable.query';
import { selectAllPageableGenericsFilters } from './generics.selector';
import { DictionaryType } from '@shared/models/enums/DictionaryType';
import { MyBaseApiCaller } from '@shared/api-services/base-api-caller/my-base-api-caller.service';
import { ApiCallerFactory } from '@shared/api-services/base-api-caller/api-caller-factory';

@Injectable()
export class GenericsEffects {
  loadPageableGenerics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableGenerics),
      withLatestFrom(this.store$.select(selectAllPageableGenericsFilters)),
      concatMap((data) => {
        const key = data[0].key as DictionaryType;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        let apiCallerService =
          this.apiCallerFactory.getApiImplementation<any>(key);

        return apiCallerService.getPageableItems(filter.value).pipe(
          map((_) => loadPageableGenericsSuccess({ data: _, key })),
          catchError((error) => of(loadPageableGenericsFailure({ error })))
        );
        //tu ejst total git
      })
    )
  );

  setPageableGenericsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableGenericsFilters),
      concatMap(({ data, key }) => {
        return of(data).pipe(
          map((_) => setPageableGenericsFiltersSuccess({ data, key })),
          finalize(() => this.reloadGenerics(key)),
          catchError((error) =>
            of(setPageableGenericsFiltersFailure({ error }))
          )
        );
      })
    )
  );

  private reloadGenerics(key: DictionaryType) {
    this.store$.dispatch(loadPageableGenerics({ key }));
  }

  filters: GetPageableQuery;
  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private apiCallerFactory: ApiCallerFactory
  ) {}
}
