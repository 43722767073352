import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

export class ReportForm extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(undefined),
      wsEmail: new UntypedFormControl(null),
      wsIdZus: new UntypedFormControl(null),
      wsImie: new UntypedFormControl(null),
      wsNazwisko: new UntypedFormControl(null),
      wsTelefon: new UntypedFormControl(null),
      wsTresc: new UntypedFormControl(null),
      wsUsWyk: new UntypedFormControl(null),
      wsType: new UntypedFormControl(null),
      wsUmowa: new UntypedFormControl(null),
      wsLokalizacja: new UntypedFormControl(null),
      wsIdZew: new UntypedFormControl(null),
      wsData: new UntypedFormControl(Date),

      attachments: new UntypedFormControl(null),
      wsParameters: new UntypedFormArray([]),

      //to send

      wsTrescToSend: new UntypedFormControl(null),
      wsTypeToSend: new UntypedFormControl(null),

      userRole: new UntypedFormControl(null),
    });
  }
  forAdd() {
    return this;
  }
}
