import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DictionaryModel } from '../../models/dictionary-model';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { EnumType } from 'typescript';

@Component({
  selector: 'app-options-select',
  templateUrl: './options-select.component.html',
  styleUrls: ['./options-select.component.scss'],
})
export class OptionsSelectComponent implements OnInit {
  @ViewChild('matSelect') matSelect: MatSelect;
  @Input() form: UntypedFormControl;
  @Input() options: any[];
  @Input() label: string;
  @Input() type: 'dictionary' | 'enum' = 'dictionary';
  @Input() enumName: string;
  @Output() selectionChange= new EventEmitter();
  get selectionStatus(): 'all' | 'none' | 'intermediate' {
   
    return 'intermediate';
  }

  constructor() {}

  ngOnInit(): void {}

  public selectAllRows() {
    const selection = this.selectionStatus === 'all' ? [] : this.options.map((_) => this.mapToId(_));

    this.form.setValue(selection);
  }

  onSelection($event)
{
  this.selectionChange.emit($event);
}
  private mapToId(value: DictionaryModel<string> | EnumType) {
    switch (this.type) {
      case 'dictionary':
        return (value as DictionaryModel<string>).id;
      case 'enum':
        return value;
      default:
        throw new Error(`Type ${this.type} is not implemented`);
    }
  }
}
