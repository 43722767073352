import { PageableDto } from '@shared/models/contracts/pageable.dto';
import { Action, createReducer, on } from '@ngrx/store';
import {
  loadPageableReports,
  loadPageableReportsSuccess,
  loadPageableReportsFailure,
  loadPdf,
  loadPdfFailure,
  loadPdfSuccess,
  loadReportHistory,
  loadReportHistoryFailure,
  loadReportHistorySuccess,
  loadReport,
  loadReportFailure,
  loadReportSuccess,
} from './reports.actions';
import { ReportDto } from '@shared/models/report-dto';
import { ReportHistoryDto } from '@shared/models/report-history.dto';
import { AttachmentProgressDto } from '@shared/models/attachment-progress.dto';

export const contractorFeatureKey = 'report';

export interface State {
  pageableReports?: PageableDto<ReportDto>;
  pdf?: Blob;
  history?: ReportHistoryDto;
  attachmentsProgress?: AttachmentProgressDto[];
  report?: ReportDto;

}

export const initialState: State = {};

const reportReducer = createReducer(
  initialState,

  on(loadPageableReports, (state) => setPageableReports(state, null)),
  on(loadPageableReportsSuccess, (state, { data }) => setPageableReports(state, data)),
  on(loadPageableReportsFailure, (state, action) => setPageableReports(state, null)),

  on(loadReportHistory, (state) => setHistory(state, null)),
  on(loadReportHistorySuccess, (state, { data }) => setHistory(state, data)),
  on(loadReportHistoryFailure, (state, action) => setHistory(state, null)),

  
  on(loadPdf, (state) => setPdf(state, null)),
  on(loadPdfSuccess, (state, { data }) => setPdf(state, data)),
  on(loadPdfFailure, (state, action) => setPdf(state, null)),

  on(loadReport, (state) => setReport(state, null)),
  on(loadReportSuccess, (state, { data }) => setReport(state, data)),
  on(loadReportFailure, (state, action) => setReport(state, null))
);

function setPageableReports(state: State, pageableReports: PageableDto<ReportDto>): State {
  return {
    ...state,
    pageableReports,
  };
}

function setHistory(state: State, data: ReportHistoryDto) {
  return {
    ...state,
    history: data,
  };
}

function setReport(state: State, report: ReportDto): State {
  return {
    ...state,
    report,
  };
}


function setPdf(state, data) {
  return { ...state, pdf: data };
}
export function reducer(state: State | undefined, action: Action) {
  return reportReducer(state, action);
}
