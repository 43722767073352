import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavItem } from '@shared/widgets/menu-list-item/nav-item';
import { SecurityService } from '@shared/services/security.service';
import { loadAllClaimsSuccess } from '@store/user-data/user-data.actions';
import { AppPermission } from '@shared/models/enums/app-permission.enum';

@Injectable({ providedIn: 'root' })
@UntilDestroy()
export class MainMenuItens {
  private items$: BehaviorSubject<NavItem[]>;
  constructor(
    private securityService: SecurityService,
    public oidcSecurityService: OidcSecurityService,
    updates$: Actions
  ) {
    this.items$ = new BehaviorSubject<NavItem[]>(this.getFiltered());

    // this.authenticationService.currentUser
    //   .pipe(untilDestroyed(this))
    //   .subscribe(() => this.items$.next(this.getFiltered()));

    updates$.pipe(ofType(loadAllClaimsSuccess)).subscribe(() => {
      this.items$.next(this.getFiltered());
    });
  }

  public getNavItems(): Observable<NavItem[]> {
    return this.items$.asObservable();
  }

  private getFiltered() {
    const items = cloneDeep(navItems);
    const result = this.filterForClaims(items);

    return result;
  }

  private filterForClaims(items: NavItem[]) {
    const filtered = items.filter(
      (_) => _.claims == null || this.securityService.hasClaims(_.claims, _.strictClaims !== true)
    );

    filtered.forEach((i) => {
      if (i.children != null) {
        i.children = this.filterForClaims(i.children);
      }
    });

    return filtered;
  }
}

const navItems: NavItem[] = [
  {
    displayName: 'Zgłoszenia',
    iconName: 'assignment',
    // children:[
    //   {
    //     displayName: 'Zgłoszenia telefonia',
    //     iconName: 'format_list_bulleted',
    //     route: '/authorised/reports',
    //     params: { }
    //   },
    //   {
    //     displayName: 'Serwis telefonów',
    //     iconName: 'construction',
    //     route: '/authorised/reports',
    //     params: { type: 'service'}
    //   },
    //   {
    //     displayName: 'Rozwój systemu',
    //     iconName: 'settings',
    //     route: '/authorised/reports',
    //     params: { type: 'improve'}
    //   },
    // ]
  },
  {
    displayName: 'Urządzenia',
    iconName: 'settings_input_component',
    route: '/authorised/devices',
    params: {}
  },
  {
    displayName: 'Użytkownicy',
    iconName: 'people_alt',
    route: '/authorised/users',
    params: {}
  },
  {
    displayName: 'Odbiorcy wszystkich powiadomień',
    iconName: 'notifications',
    route: '/authorised/supervisors',
    params: {},
    claims: [AppPermission.AccessAll]
  },
];


