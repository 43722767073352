<mat-form-field>
  <mat-label>{{ label }}</mat-label>
  <mat-select [formControl]="form" multiple #matSelect (selectionChange)="onSelection($event)">
    <button style="width: 100%;" mat-raised-button (click)="selectAllRows()">
      <span *ngIf="selectionStatus !== 'all'; else deselectAll">{{ 'Common.CheckAll' | translate }}</span>
    </button>
    <ng-template #deselectAll>
      <span>{{ 'Common.DecheckAll' | translate }}</span>
    </ng-template>
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="'dictionary'">
        <mat-option *ngFor="let status of options" [value]="status.id" >
          {{ status.name }}
        </mat-option>
      </ng-container>
      <ng-container *ngSwitchCase="'enum'">
        <mat-option *ngFor="let status of options" [value]="status">
          {{ 'Enums.' + enumName + '.' + status | translate }}
        </mat-option>
      </ng-container>
    </ng-container>
  </mat-select>


</mat-form-field>
