import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import * as signalR from '@microsoft/signalr';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private hub: signalR.HubConnection;

  public startConnection = async (): Promise<void> => {
    this.hub = new signalR.HubConnectionBuilder()
      .withUrl(environment.apiRoot + '/notifications', {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();

    try {
      await this.hub.start();
      return;
    } catch (err) {
      return;
    }
  };

  public joinGroup(groupName: string): void {
    this.hub.invoke('JoinGroup', groupName);
  }

  public leaveGroup(groupName: string): void {
    this.hub.invoke('LeaveGroup', groupName);
  }

  public onReceiveReportNotification(
    callback: (notification: any) => void
  ): void {
    this.hub.on('ReceiveReportNotification', callback);
  }

  public onRemoveReportNotification(
    callback: (notification: any) => void
  ): void {
    this.hub.on('RemoveReportNotification', callback);
  }
}
