import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { BaseApiCaller, HttpOptions } from './base-api-caller';
import { Observable } from 'rxjs';
import { SearchModel } from '@shared/models/contracts/search-model';
import { SelectModel } from '@shared/models/contracts/select-model';
import { GetPageableDictionaryQuery } from '@shared/queries/get-pageable-dictionary.query';
import { PageableDto } from '@shared/models/pageable.dto';
import { map } from 'rxjs/operators';

export class MyBaseApiCaller<T> extends BaseApiCaller {
  [x: string]: any;
  constructor(protected override httpClient: HttpClient) {
    super(httpClient);
  }
  isIdentity: boolean = false

  public setIdentityControllerPath(controllerPath: string) {
    this.controllerPath = controllerPath;
    this.isIdentity = true;
  }


  public setControllerPath(controllerPath: string) {
    this.controllerPath = controllerPath;
    this.isIdentity = false;
  }

  protected override getFullPath(path: string) {
    if(!this.isIdentity)
      return environment.apiRoot + '/api/' + this.controllerPath + '/' + path;
    return environment.identityRoot + '/' + path;
  }

  public addItemWithPath(dto: T, path?: string) {
    return this.post(path + '/', dto);
  }
  public putItemWithPath(dto: T, path: string) {
    return this.put(path + '/', dto);
  }
  public getItemWithPath(path: string): Observable<T> {
    return this.get<T>(path).pipe(map((_) => _ as T));
  }

  public getItemWithPathAndParams(path: string, model: any): Observable<T> {
    var params = this.prepareParams(model);
    return this.get<T>(path, { params }).pipe(map((_) => _ as T));
  }

  public getItemWithResponseAndParams(path: string, model: any): Observable<T> {
    const body = {
      ...model
    };

    return this.getFullResponse<T>(path, body).pipe(map((_) => _ as T));
  }
  public getItemWithResponse(path: string): Observable<T> {
    return this.getWithoutMapping<T>(path).pipe(map((_) => _ as T));
  }
  public addItem(dto: T) {
    return this.post('', dto);
  }

  // public updateToTemplate(dto: T) {
  //   return this.put('update-to-template', dto);
  // }

  public addRange(dto: T) {
    return this.put('create-range', dto);
  }
  public deleteRange(dto: T) {
    return this.deleteWithBody('delete-range', dto);
  }

  // public restoreLabels(dto: T){
  //   return this.put('',dto);
  // }

  public addTemplate(dto: T) {
    return this.put('create-range-from-template', dto);
  }

  public updateItem(dto: T) {
    return this.put('', dto);
  }

  public updateProject(dto: T) {
    return this.put('update-codes', dto);
  }

  public postItemWithPath(dto: T, path: string) {
    return this.post(path + '/', dto);
  }

  public getForSelect(filter: SearchModel) {
    return this.get<SelectModel<string>[]>('selectable', {
      params: this.prepareParams(filter),
    });
  }

  public deleteItem(uniqueId: string, path?: string) {
    let actionPath = '';
    if (path != null) {
      actionPath += path + '/';
    }

    actionPath += uniqueId;

    return this.delete(actionPath);
  }

  public getItem(uniqueId: string): Observable<T> {
    return this.getById<T>(uniqueId.toString()).pipe(map((_) => _ as T));
  }

  public getPageableItems(query: GetPageableDictionaryQuery) {
    const params = this.prepareParams(query);
    return this.get<PageableDto<T>>('pageable', { params });
  }
  public getAll(): Observable<T> {
    return this.get<T>('').pipe(map((_) => _ as T));
  }

  public postFile(file: File, name: string) {
    const formData = new FormData();
    formData.append('file', file[0]);
    formData.append('name', name);

    return this.httpClient.post<any>(this.getFullPath(''), formData);
  }

  public postFiles(files: any, labelId?: string, transportId?: string): Observable<any> {
    const formData = new FormData();
    const filesArray = Array.from(files);

    filesArray.forEach((file: any) => {
      formData.append('files', file);
    });
    formData.append('labelId', labelId || '');
    formData.append('transportId', transportId || '');

    return this.httpClient.post<any>(this.getFullPath(''), formData);
  }

  public postAttachments(files: any, areaId?: string, sectionId?: string): Observable<any> {
    const formData = new FormData();
    const filesArray = Array.from(files);

    filesArray.forEach((file: any) => {
      formData.append('files', file);
    });
    formData.append('areaId', areaId || '');
    formData.append('sectionId', sectionId || '');

    return this.httpClient.post<any>(this.getFullPath(''), formData);
  }



  public getFile(uniqueId: string, path: string) {
    return this.httpClient.get(this.getFullPath('') + path + '/' + uniqueId, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  public exportToFile(
    pathName: string,
    content_Type: string,
    filters?: any
  ): Observable<any> {
    const params = this.prepareParams(filters);
    return this.httpClient.get(this.getFullPath('') + pathName + '?' + params, {
      responseType: 'blob',
      headers: {
        'Content-Type': content_Type,
      },
    });
  }

}
