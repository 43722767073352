import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectModel } from '@shared/models/contracts/select-model';
import { Observable } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-autocomplete-with-button',
  templateUrl: './autocomplete-with-button.component.html',
  styleUrls: ['./autocomplete-with-button.component.scss'],
})
export class AutocompleteWithButtonComponent implements OnInit, OnDestroy {
  @ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger;
  @ViewChild('dom', { static: true }) dom;
  @Input() set itemLink(value: any[] | string) {
    if (value != null && ((Array.isArray(value) && value.some((_) => _ == null)) || value.length === 0)) {
      return;
    }
    this._routerLink = value;
  }
  get itemLink() {
    return this._routerLink;
  }
  @Input() form: UntypedFormGroup;
  @Input() placeholder: string;
  @Input() filterFunc: (_: string) => Observable<SelectModel<any>[]>;
  @Input() label: string;
  @Input() searchLabel: string;
  @Input() readonly: boolean = false;
  @Input() idField = 'id';
  @Input() labelField = 'name';
  @Input() set selectedItem(value) {
    this._selectedItem = value;
  }
  get selectedItem() {
    return this._selectedItem;
  }
  @Input() data: SelectModel<any>[];
  @Input() hideButton: boolean;
  @Output() buttonClick = new EventEmitter<boolean>();
  @Output() valueChange = new EventEmitter();

  searchForm = new UntypedFormControl();
  loading = false;

  private _routerLink: any[] | string;
  private _selectedItem;

  constructor() {}

  ngOnDestroy() {}

  ngOnInit() {
    this.validateInputs();
    this.searchForm.valueChanges
      .pipe(
        tap(() => this.setLoading(true)),
        debounceTime(500),
        untilDestroyed(this)
      )
      .subscribe((_) => {
        this.doFilter(_);
      });

    this.searchForm.setValidators(this.validateForm());
    this.getValuesOnInit();
  }

  getValuesOnInit() {
    var label = this.form.get(this.labelField).value;
    this.searchForm.patchValue(label, { emitEvent: true, onlySelf: true });
  }

  selectionChanged() {
    let currentId = this.form.get(this.idField).value;
    let newLabel = this.data.find((_) => {
      return _.id.toString() == currentId.toString();
    });
    this.form.get(this.labelField).patchValue(newLabel.label);
    //this.form.get(this.labelField).patchValue(this.data[newLabel]);

    // this.form.get(this.labelField).patchValue(value, { emitEvent: false, onlySelf: true });
    // this.valueChange.emit(value);
  }

  getFieldValue(data: SelectModel<any>) {
    const result = {};
    result[this.idField] = data.id;
    result[this.labelField] = data.label;

    return result;
  }

  // clear() {
  //   this.searchForm.setValue(null);
  //   this.form.patchValue(this.getIdValuePair(null, null));
  // }

  clear(e: any) {
    e.stopPropagation()
    this.searchForm.setValue(null);
    this.form.patchValue(this.getIdValuePair(null, null));
  }

  // validateSearch() {
  //   this.searchForm.updateValueAndValidity();
  // }

  validateForm(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const idValid = this.form.get(this.idField).valid;

      return idValid ? null : { idRequired: true };
    };
  }

  public showSelectedItem(data: SelectModel<any>[]): boolean {
    return this._selectedItem != null && (data == null || !data.map((_) => _.id).includes(this._selectedItem.id));
  }

  private getIdValuePair(id, value) {
    let values = {};
    values[this.labelField] = value;
    values[this.idField] = id;

    return values;
  }

  private doFilter(search: string): void {
    this.filterFunc(search);
    this.setLoading(false);
  }

  private setLoading(value: boolean) {
    this.loading = value;
  }

  private validateInputs() {
    if (this.form == null) {
      throw new Error('Input form cannot be null');
    }
  }

   onButtonClick(event: any) {
    event.stopPropagation();
    this.buttonClick.emit();
  }
}
