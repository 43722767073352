import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { MaterialModule } from '../material.module';
import { PipesModule } from '../pipes/pipes.module';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { EditActionsComponent } from './edit-actions/edit-actions.component';
import { ErrorSendingAttachmentsComponent } from './error-message/error-sending-attachments.component';
import { SnackBarMessagesComponent } from './error-message/snack-bar-messages.component';
import { FilesUploadComponent } from './files-upload/files-upload.component';
import { InputComponent } from './input/input.component';
import { LoaderSpinnerComponent } from './loader-spinner/loader-spinner.component';
import { MatTableComponent } from './mat-table/mat-table.component';
import { MenuListItemComponent } from './menu-list-item/menu-list-item.component';
import { SelectSearchComponent } from './select-search/select-search.component';
import { SelectableTableComponent } from './selectable-table/selectable-table.component';
import { SpinnerButtonComponent } from './spinner-button/spinner-button.component';
import { StepperNavigationComponent } from './stepper-navigation/stepper-navigation.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { RangePickerComponent } from './range-picker/range-picker.component';
import { BaseDialogComponent } from './base-dialog/base-dialog.component';
import { ContactEntryComponent } from './contact-entry/contact-entry.component';
import { OptionsSelectComponent } from './options-select/options-select.component';
import { AutocompleteShowAllComponent } from './autocomplete-show-all/autocomplete-show-all.component';
import { AutocompleteWithButtonComponent } from './autocomplete-with-button/autocomplete-with-button.component';

@NgModule({
    declarations: [
        SelectSearchComponent,
        InputComponent,
        SpinnerButtonComponent,
        MenuListItemComponent,
        LoaderSpinnerComponent,
        AutocompleteComponent,
        EditActionsComponent,
        UnderConstructionComponent,
        FilesUploadComponent,
        ErrorSendingAttachmentsComponent,
        DeleteDialogComponent,
        SnackBarMessagesComponent,
        StepperNavigationComponent,
        MatTableComponent,
        SelectableTableComponent,
        RangePickerComponent,
        BaseDialogComponent,
        ContactEntryComponent,
        OptionsSelectComponent,
        AutocompleteShowAllComponent,
        AutocompleteWithButtonComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        FontAwesomeModule,
        NgSelectModule,
        MaterialModule,
        PipesModule,
        RouterModule,
    ],
    exports: [
        SelectSearchComponent,
        InputComponent,
        SpinnerButtonComponent,
        MenuListItemComponent,
        LoaderSpinnerComponent,
        AutocompleteComponent,
        PipesModule,
        EditActionsComponent,
        UnderConstructionComponent,
        FilesUploadComponent,
        StepperNavigationComponent,
        MatTableComponent,
        SelectableTableComponent,
        RangePickerComponent,
        BaseDialogComponent,
        ContactEntryComponent,
        OptionsSelectComponent,
        AutocompleteShowAllComponent,
        AutocompleteWithButtonComponent,
    ]
})
export class WidgetsModule {}
