import { Injectable } from '@angular/core';
declare let alertify: any;

@Injectable({
  providedIn: 'root',
})
export class AlertifyService {
  constructor() {
    alertify.defaults = {
      notifier: {
        delay: 5,
        position: 'bottom-right',
        closeButton: false,
      },
    }
  }

  confirm(message: string, okCallback: () => any) {
    alertify.confirm(message, (e) => {
      if (e) {
        okCallback();
      } else {
      }
    });
  }

  success(message: string) {
    alertify.success(message);
  }

  error(message: string) {
    alertify.error(message);
  }

  warning(message: string) {
    alertify.warning(message);
  }

  message(message: string) {
    alertify.message(message);
  }

  notify(message: string, type: string, wait: number, callback?: Function) {
    alertify.notify(message, type, wait, callback);
  }
}
