import { Injectable } from '@angular/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';

@Injectable({
  providedIn: 'root',
})
export class MatPaginatorIntlPlService extends MatPaginatorIntl {
  constructor() {
    super();
  }

  itemsPerPageLabel = 'Wyników na stronę';
  nextPageLabel = 'Następna strona';
  previousPageLabel = 'Poprzednia strona';
  firstPageLabel = 'Pierwsza strona';
  lastPageLabel = 'Ostatnia strona';
  getRangeLabel = (page: number, pageSize: number, length: number) =>
    `Strona ${page + 1} z ${pageSize > 0 ? Math.ceil(length / pageSize) : 0}`;
}
