<mat-form-field class="w-100" [ngStyle]="{ 'width.px': width }">
  <mat-label>{{ label }}<span *ngIf="required==true" style="color: red;"> *</span></mat-label>
  <input  [readonly]="readonly" [type]="inputType" [step]="step" [placeholder]="placeholder" [value]="!hasFormControl() ? value : null" matInput [formControl]="form"
    [autocomplete]="autocomplete" />
  <mat-error *ngIf="form?.hasError('required')">
    {{ 'ValidationErrors.FieldRequired' | translate }}
  </mat-error>
  <mat-error *ngIf="form?.hasError('isNotUnique')">
    {{ 'ValidationErrors.IsNotUnique' | translate }}
  </mat-error>
  <mat-error *ngIf="form?.hasError('pattern')">
    {{ 'ValidationErrors.Pattern' | translate }}
  </mat-error>
  <mat-error *ngIf="form?.hasError('isNipNotUnique')">
    {{ 'ValidationErrors.isNipNotUnique' | translate }}
  </mat-error>
  <mat-error *ngIf="form?.hasError('minlength')">
    {{ 'ValidationErrors.MinLength' | translate }} {{ (form?.errors)['minlength']['requiredLength'] }}
  </mat-error>
  <mat-error *ngIf="form?.hasError('maxlength')">
    {{ 'ValidationErrors.MaxLength' | translate }} {{ (form?.errors)['maxlength']['requiredLength'] }}
  </mat-error>
</mat-form-field>
